import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import type { FieldArrayWithId } from "react-hook-form";
import { useSelector } from "react-redux";
import { InView } from "react-intersection-observer";
import { isBoolean, isEmpty, isEqual } from "lodash";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import type { RootState } from "../../../store";
import type { MetadataState } from "../slice";
import Section from "./Section";
import CellHeader from "./CellHeader";
import CompleteSubSection from "./CompleteSubSection";
import GenerateTestSubSection from "./GenerateTestSubSection";
import ReferenceCalibrationSubSection from "./ReferenceCalibrationSubSection";
import StepInfoBlock from "./StepInfoBlock";
import Button from "../../../components/Button";
import OverflowTooltip from "../../../components/OverflowTooltip";
import BaseTableRow from "../../../components/forms/BaseTableRow";
import TdCell from "../../../components/forms/TdCell";
import ThCell from "../../../components/forms/ThCell";
import ClockIcon from "../../../icons/Clock";
import { hasPassedStatus } from "../../../utils/statuses";
import {
  getCharacterizationLabelForId,
  sampleIdToString,
  CELL_META_INFO_TEXT_BY_UI_ID,
} from "../../../utils/labels";
import ReferenceCalibrationSingleSubSection from "./ReferenceCalibrationSingleSubSection";
import TeardownSubSection from "./TeardownSubSection";
import {
  CELL_ID_LOOKUP_ID,
  SAMPLE_TYPE_LOOKUP_ID,
} from "../../characterization/slice";
import ConditionCell from "../../../components/forms/ConditionCell";
import { useLocation, useNavigate } from "react-router-dom";
import QualityCheckSubSection from "./QualityCheckSubSection";
import colors from "../../../theme/colors";

type Props = {
  title: ConditionUIStepCategory["title"];
  formFields: FieldArrayWithId<MetadataRHFormFormat, "metadata">[];
  onRefreshTemplateClick: () => void;
  uiFields: ConditionMetadataLabel["fields"];
  specFields: ConditionMetadataLabel["fields"];
  specValues: DynamicFormValues;
  teardownFields?: Record<string, ConditionUIField[]>[];
  teardownTemplateRefreshAvailable?: boolean;
  samples?: CharacterizationSample[];
  step: number;
  activeStep: number;
  changeRequestKey: string;
  onSave: () => void;
  onInView: (inView: boolean, stepId: number) => void;
  onCopy: () => void;
  eventMap: EventMap;
  onViewEvents: (cell_id: number) => void;
};

type SamplesBySampleTypeAndCellId = {
  [sampleType: string]: { [cellId: string]: (string | number)[] };
};

const ConnectedSection = ({
  title,
  formFields,
  uiFields,
  specFields,
  specValues,
  teardownFields,
  teardownTemplateRefreshAvailable,
  samples,
  step,
  activeStep,
  changeRequestKey,
  onRefreshTemplateClick,
  onSave,
  onInView,
  onCopy,
  eventMap,
  onViewEvents,
}: Props) => {
  const {
    register,
    control,
    formState: { isDirty },
  } = useFormContext();

  const { pendingChanges } = useSelector<RootState, MetadataState>(
    ({ metadata }) => metadata
  );

  const visibleCellRef = useRef<number[]>([]);

  const [editingCompleted, setEditingCompleted] = useState<number[]>([]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isDirty) {
      setEditingCompleted([]);
    }
  }, [isDirty]);

  useEffect(() => {
    const { current: refVisible } = visibleCellRef;
    const nowVisible = formFields.map(({ cell_id }) => cell_id);
    if (!isEqual(refVisible, nowVisible)) {
      visibleCellRef.current = [...nowVisible];
    }
  }, [formFields]);

  const flag = Object.keys(specValues).some(
    (id) => id.includes("engineer_flag") && specValues[id]
  );

  const executorKey = Object.keys(specValues).find((id) =>
    id.includes("executor")
  );

  const executor = executorKey
    ? (specValues[executorKey] as User | null)
    : null;

  const isFullAssembly =
    title === "Full Dry Assembly" ||
    title.toLowerCase().includes("electrolyte");
  const isFill = title === "Filling";
  const isOnTest = title === "On-Test";
  const isDuringTest = title === "During Test";
  const isOffTest = title === "Off-Test";
  const isTeardown = title === "Teardown";

  const isNewTeardown =
    isTeardown &&
    teardownFields &&
    teardownFields.some((fields) => Object.keys(fields).length > 0);

  const shouldShowInitialRefCal = isFill;

  const shouldShowComplete =
    !isOnTest &&
    !isDuringTest &&
    !isNewTeardown &&
    formFields.some(
      (field) =>
        !!field.items[step].completed?.api_path.do ||
        !!field.items[step].completed?.api_path.undo
    );

  const minimumStatus = isTeardown
    ? "F"
    : isOffTest || isFill || isFullAssembly
    ? "P"
    : isOnTest
    ? "L"
    : isDuringTest
    ? "N"
    : "I";

  const maximumStatus = "U";

  const itemChanges = pendingChanges[changeRequestKey] || {};

  const samplesByTypeAndCellId: SamplesBySampleTypeAndCellId | null =
    useMemo(() => {
      if (!isTeardown || isEmpty(samples)) {
        return null;
      }
      return samples!.reduce(
        (
          returnObj: SamplesBySampleTypeAndCellId,
          sample_: CharacterizationSample
        ) => {
          const sampleType = sample_.fields.find(
            (sampleField) =>
              sampleField.field_lookup_id === SAMPLE_TYPE_LOOKUP_ID
          )!.value! as string;
          const cellId = sample_.fields.find(
            (sampleField) => sampleField.field_lookup_id === CELL_ID_LOOKUP_ID
          )!.value! as string;
          if (!returnObj[sampleType]) {
            returnObj[sampleType] = {};
          }
          returnObj[sampleType][cellId] = [
            ...(returnObj[sampleType][cellId] || []),
            sample_.sample_id!,
          ];
          return returnObj;
        },
        {}
      );
    }, [samples, isTeardown]);

  return (
    <InView onChange={(inView) => onInView(inView, step)}>
      {({ ref }) => (
        <Section
          ref={ref}
          step={step}
          activeStep={activeStep}
          title={title}
          flag={flag}
          executor={executor}
        >
          <StepInfoBlock
            changeRequestKey={changeRequestKey}
            values={specValues}
            fields={specFields}
            isOnTest={isOnTest}
          />

          {isDuringTest ? (
            <Box my={4}>
              <Typography>Calibration Voltage Measurements</Typography>
            </Box>
          ) : null}

          {isOffTest ? (
            <Box my={4}>
              <Typography>EOL Cell Quality Checks</Typography>
            </Box>
          ) : null}

          {teardownTemplateRefreshAvailable && isNewTeardown && (
            <>
              <Box my={4}>
                <Typography sx={{ fontStyle: "italic" }}>
                  Note: There are updated teardown templates available for this
                  assembly type. Click below to apply the updates.
                </Typography>
              </Box>
              <Box my={4}>
                <Button
                  color="primary"
                  size="small"
                  onClick={onRefreshTemplateClick}
                >
                  Apply Teardown Template Updates
                </Button>
              </Box>
            </>
          )}

          {isOnTest && formFields.some((cell_) => cell_.quality_check) ? (
            <>
              <Box
                my={4}
                style={{
                  borderTop: `1px solid ${colors.rules}`,
                  maxWidth: 360,
                }}
              >
                <Typography mt={4}>Quality Checks</Typography>
              </Box>
              <QualityCheckSubSection
                formFields={formFields}
                executor={executor}
              />
            </>
          ) : null}

          <Box my={4}>
            <Table size="small" style={{ width: "auto" }}>
              {uiFields.length > 0 ? (
                <>
                  <TableHead>
                    <BaseTableRow>
                      <TableCell>METADATA NAME</TableCell>
                      <TableCell>SPECIFIED VALUE</TableCell>
                      {formFields.map((field, fieldIndex) => (
                        <CellHeader
                          key={fieldIndex}
                          cell_id={field.cell_id}
                          isReadyOff={
                            isOffTest &&
                            ["N", "P"].includes(field.status) &&
                            !!field.ready_off?.ready_off__completed_at
                          }
                          hasEvents={field.cell_id in eventMap}
                          onViewEvents={onViewEvents}
                        />
                      ))}
                    </BaseTableRow>
                  </TableHead>
                  <TableBody>
                    {uiFields.map(
                      (
                        { label, spec_field, meta_field: uiField },
                        metaFieldIndex
                      ) => (
                        <BaseTableRow key={metaFieldIndex}>
                          <ThCell
                            largeWidth
                            required={uiField.required}
                            title={label}
                            help={
                                uiField?.id in CELL_META_INFO_TEXT_BY_UI_ID
                                ? CELL_META_INFO_TEXT_BY_UI_ID[uiField?.id] : null
                            }
                          >
                            {label}
                          </ThCell>
                          <ThCell smallWidth>
                            <OverflowTooltip wrap title={title}>
                              <span className="small">
                                {isBoolean(specValues[spec_field?.id]) &&
                                specValues[spec_field?.id]
                                  ? "☑️"
                                  : specValues[spec_field?.id]}
                              </span>
                            </OverflowTooltip>
                            {itemChanges[spec_field?.id] !== undefined ? (
                              <Box ml="auto">
                                <Tooltip
                                  arrow
                                  title={`Pending specification change to ${
                                    itemChanges[spec_field?.id]
                                  }`}
                                >
                                  <div style={{ height: 20, width: 24 }}>
                                    <ClockIcon
                                      style={{ width: 20, height: 20 }}
                                    />
                                  </div>
                                </Tooltip>
                              </Box>
                            ) : null}
                          </ThCell>
                          {formFields.map((field, fieldIndex) => (
                            <Controller
                              key={`${uiField.id}-${field.id}`}
                              name={`metadata.${fieldIndex}.items.${step}.${uiField.id}`}
                              control={control}
                              defaultValue={field.items[step][uiField.id]}
                              rules={{
                                required: uiField.required,
                              }}
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid },
                              }) => (
                                <TdCell
                                  ref={ref}
                                  className={
                                    field.items[step].completed?.completed_at &&
                                    !editingCompleted.includes(field.cell_id)
                                      ? `${
                                          isOnTest ? "textPrimary" : ""
                                        } componentCompleted`
                                      : ""
                                  }
                                  name={name}
                                  uiField={uiField}
                                  conditionKey={`metadata.${fieldIndex}.items.${step}`}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  value={value}
                                  error={invalid}
                                  disabled={
                                    uiField.external ||
                                    !hasPassedStatus(
                                      minimumStatus,
                                      field.status
                                    ) ||
                                    hasPassedStatus(
                                      maximumStatus,
                                      field.status,
                                      false
                                    ) ||
                                    isOnTest ||
                                    (!editingCompleted.includes(
                                      field.cell_id
                                    ) &&
                                      !!field.items[step].completed
                                        ?.completed_at)
                                  }
                                  endAdornment={
                                    isOnTest &&
                                    uiField.id.includes("test_filename") &&
                                    value ? (
                                      <Button
                                        color="tertiary"
                                        type="button"
                                        size="small"
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            `${value}`
                                          );
                                          if (onCopy) {
                                            onCopy();
                                          }
                                        }}
                                        style={{
                                          padding: "0.25rem",
                                          minWidth: "auto",
                                        }}
                                      >
                                        <b>Copy</b>
                                      </Button>
                                    ) : undefined
                                  }
                                />
                              )}
                            />
                          ))}
                        </BaseTableRow>
                      )
                    )}
                  </TableBody>
                </>
              ) : null}

              {isDuringTest ? (
                <ReferenceCalibrationSubSection
                  onSave={onSave}
                  step={step}
                  formFields={formFields}
                  executor={executor}
                  eventMap={eventMap}
                  onViewEvents={onViewEvents}
                />
              ) : null}

              {shouldShowInitialRefCal ? (
                <ReferenceCalibrationSingleSubSection
                  onSave={onSave}
                  formFields={formFields}
                  executor={executor}
                  minimumStatus={minimumStatus}
                  maximumStatus={maximumStatus}
                  eventMap={eventMap}
                  onViewEvents={onViewEvents}
                />
              ) : null}

              {isOnTest ? (
                <GenerateTestSubSection
                  onSave={onSave}
                  step={step}
                  formFields={formFields}
                  executor={executor}
                />
              ) : null}

              {isNewTeardown ? (
                <TeardownSubSection
                  onSave={onSave}
                  teardownFields={teardownFields}
                  step={step}
                  formFields={formFields}
                  executor={executor}
                  eventMap={eventMap}
                  onViewEvents={onViewEvents}
                />
              ) : null}

              {shouldShowComplete ? (
                <CompleteSubSection
                  validateInitialRefCal={shouldShowInitialRefCal}
                  minimumStatus={minimumStatus}
                  maximumStatus={maximumStatus}
                  onSave={onSave}
                  step={step}
                  formFields={formFields}
                  executor={executor}
                  editingCells={editingCompleted}
                  onEdit={setEditingCompleted}
                  showReadyOff={isOffTest}
                  eventMap={eventMap}
                  onViewEvents={onViewEvents}
                  isOffTest={isOffTest}
                />
              ) : null}
            </Table>

            {formFields.map((field, fieldIndex) => (
              <Fragment key={`hidden-${field.id}`}>
                <input
                  key={`hidden-${field.id}-api_path`}
                  {...register(`metadata.${fieldIndex}.items.${step}.api_path`)}
                  type="hidden"
                  defaultValue={field.items[step].api_path}
                />
                {field.items[step].completed?.api_path.do ? (
                  <input
                    key={`hidden-${field.id}-complete-api_path`}
                    {...register(
                      `metadata.${fieldIndex}.items.${step}.completed.api_path.do`
                    )}
                    type="hidden"
                    defaultValue={field.items[step].completed.api_path.do}
                  />
                ) : null}
                {field.items[step].completed?.api_path.undo ? (
                  <input
                    key={`hidden-${field.id}-uncomplete-api_path`}
                    {...register(
                      `metadata.${fieldIndex}.items.${step}.completed.api_path.undo`
                    )}
                    type="hidden"
                    defaultValue={field.items[step].completed.api_path.undo}
                  />
                ) : null}
                <input
                  key={`hidden-${field.id}-complete-time`}
                  {...register(
                    `metadata.${fieldIndex}.items.${step}.completed.completed_at`
                  )}
                  type="hidden"
                  defaultValue={field.items[step].completed?.completed_at}
                />
                <input
                  key={`hidden-${field.id}-test_meta_id`}
                  {...register(
                    `metadata.${fieldIndex}.items.${step}.test_meta_id`
                  )}
                  type="hidden"
                  defaultValue={field.items[step].test_meta_id}
                />
              </Fragment>
            ))}
          </Box>

          {!isEmpty(samplesByTypeAndCellId) && (
            <Box my={8}>
              <Typography>Characterization Samples</Typography>
              <Table size="small" style={{ width: "auto" }}>
                <>
                  <TableHead>
                    <BaseTableRow>
                      <TableCell />
                      <TableCell>SAMPLE TYPE</TableCell>
                      {formFields.map((field, fieldIndex) => (
                        <CellHeader key={fieldIndex} cell_id={field.cell_id} />
                      ))}
                    </BaseTableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(samplesByTypeAndCellId!).map(
                      (sampleType, metaFieldIndex) => (
                        <BaseTableRow key={metaFieldIndex}>
                          <ConditionCell largeWidth />
                          <ThCell
                            title={getCharacterizationLabelForId(sampleType)}
                            smallWidth
                          >
                            {getCharacterizationLabelForId(sampleType)}
                          </ThCell>

                          {formFields.map((field) => (
                            <ConditionCell
                              key={`${field.cell_id}-${sampleType}`}
                            >
                              {samplesByTypeAndCellId![sampleType][
                                field.cell_id
                              ]?.map((sampleId_) => (
                                <Box key={`${sampleId_}-link`}>
                                  <Button
                                    color="tertiary"
                                    style={{
                                      padding: "0 4",
                                    }}
                                    onClick={() => {
                                      navigate(
                                        `/characterization/samples?sample_id=${sampleId_}`,
                                        {
                                          state: {
                                            from: pathname,
                                          },
                                        }
                                      );
                                    }}
                                  >
                                    {sampleIdToString(sampleId_)}
                                  </Button>
                                </Box>
                              ))}
                            </ConditionCell>
                          ))}
                        </BaseTableRow>
                      )
                    )}
                  </TableBody>
                </>
              </Table>
            </Box>
          )}
        </Section>
      )}
    </InView>
  );
};

export default ConnectedSection;
