/**
 * Modal dialog to enter initial sample parameters, and following full-page form
 * to fill in full sample details and apply validation / required-field rules.
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import BaseTableRow from "../../components/forms/BaseTableRow";
import ThCell from "../../components/forms/ThCell";
import InputCell from "../../components/forms/InputCell";
import TextareaCell from "../../components/forms/TextareaCell";
import ConditionCell from "../../components/forms/ConditionCell";
import Button from "../../components/Button";
import AdapterLuxon from "@mui/lab/AdapterLuxon";

import {
  useForm,
  Controller,
  useFieldArray,
  useWatch,
  FieldPath,
} from "react-hook-form";
import styled from "@mui/styles/styled";
import ArrowBackIcon from "../../icons/ArrowBack";
import colors from "../../theme/colors";
import MuiDateTimePicker from "@mui/lab/DateTimePicker";
import {
  ALL_FORM_FACTOR_OPTIONS,
  ANODE_FORM_FACTOR_OPTIONS,
  ANODE_MATERIAL_OPTIONS,
  cellIdToString,
  CHARACTERIZATION_SAMPLE_TYPES,
  ELYTE_STATE_OPTIONS,
  ELYTE_STORAGE_OPTIONS,
  expIdToString,
  GDE_MATERIAL_OPTIONS,
  OEE_MATERIAL_OPTIONS,
  OEE_MESH_TYPE_OPTIONS,
  QUANTITY_TYPE_OPTIONS,
  sampleIdToString,
  SAMPLE_LOCATION_OPTIONS,
  SAMPLE_TYPE_SLUDGE_OPTIONS,
  SANDBLASTED_OPTIONS,
  ELYTE_SAMPLE_LOCATION_OPTIONS,
  ASANA_CHARACTERIZATION_SAMPLE_LOCATION_OPTIONS,
  ASANA_CHARACTERIZATION_TASK_TEMPLATE_ID_OPTIONS,
  ASANA_CHARACTERIZATION_ANALYSIS_TYPE_OPTIONS,
} from "../../utils/labels";
import MetaCell from "../../components/forms/MetaCell";
import CheckboxCell from "../../components/forms/CheckboxCell";
import {
  ALTERNATE_MATERIAL_KEYS_FOR_SAMPLE_TYPE,
  ANALYSIS_TYPE_LOOKUP_ID,
  ASANA_TASK_TEMPLATE_ID_LOOKUP_ID,
  CELL_ID_LOOKUP_ID,
  CharacterizationState,
  CREATE_ASANA_TASK_LOOKUP_ID,
  DATE_SAMPLE_HARVESTED_LOOKUP_ID,
  ELYTE_LOOKUP_ID,
  ELYTE_STATE_LOOKUP_ID,
  EXP_ID_LOOKUP_ID,
  getMaterials,
  HOT_POCKET_LOOKUP_ID,
  ICM_PCM_LOOKUP_ID,
  LAB_LOCATION_LOOKUP_ID,
  LOCATION_LOOKUP_ID,
  QUANTITY_UNIT_LOOKUP_ID,
  resetGetMaterials,
  resetSaveSamples,
  SAMPLE_TYPE_LOOKUP_ID,
  saveSamples,
} from "./slice";
import { RootState } from "../../store";
import Toast from "../../components/Toast";
import PlusCircle from "../../icons/PlusCircle";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
import { isEmpty, isNull } from "lodash";
import UserFilter from "../../components/table/UserFilter";
import { LocalizationProvider } from "@mui/lab";
import Times from "../../icons/Times";

const HeaderContainer = styled(Paper)({
  width: "100vw",
  zIndex: 1,
  position: "fixed",
});

const SHARED_ROW_FIELDS = [QUANTITY_UNIT_LOOKUP_ID];

const CONDITIONAL_FIELD_CONFIG: {
  [key: string]: [string, string[] | boolean[]];
} = {
  elyte_state_description: [ELYTE_STATE_LOOKUP_ID, ["other"]],
  elyte_storage: [ELYTE_STATE_LOOKUP_ID, ["pristine"]],
  create_asana_task: [
    SAMPLE_TYPE_LOOKUP_ID,
    CHARACTERIZATION_SAMPLE_TYPES.filter(
      (sampleType) => sampleType.enabled
    ).map((sampleType) => sampleType.id),
  ],
  form_factor_other_description: ["form_factor", ["other"]],
  location_other_description: [LOCATION_LOOKUP_ID, ["other"]],
  hot_pocket_pcm: [HOT_POCKET_LOOKUP_ID, [true]],
  seal_type: [HOT_POCKET_LOOKUP_ID, [true]],
};
const FIELDS_TO_HIDE_IN_CELL_MODAL_FORM = [CELL_ID_LOOKUP_ID];

// See notes for save_characterization_form_records (in backend/meta/tasks.py) for
// how fields in SamplesForm are configured
const SamplesForm = ({
  from,
  samplesToEdit,
  originCellIds,
  modalForm = false,
  batchCreateAfterModalForm = false,
  onCompleteModalForm,
}: {
  samplesToEdit?: CharacterizationSample[];
  from?: string;
  originCellIds?: string[];
  modalForm?: boolean;
  batchCreateAfterModalForm?: boolean;
  onCompleteModalForm?: (newSamplesFromModal: CharacterizationSample[]) => void;
}) => {
  const {
    emptySample,
    createdSampleIdsToCellIds,
    retrievedMaterialsForSampleIndex,
    error: { saveSamples: createSampleError },
    status: { getMaterials: getMaterialsStatus },
  } = useSelector<RootState, CharacterizationState>(
    ({ characterization }) => characterization
  );
  const [copyingFromModalForm, setCopyingFromModalForm] = useState(false);
  const [savedValChanged, setSavedValChanged] = useState(false);
  const [numberSamplesToCreate, setNumberSamplesToCreate] = useState("1");
  const [isSampleEdit, setIsSampleEdit] = useState(false);
  const createdSampleIds = Object.keys(createdSampleIdsToCellIds);

  const dispatch = useDispatch();
  // Form management
  const { control, handleSubmit, formState, getValues, setValue } =
    useForm<CharacterizationSamples>({
      defaultValues: {
        samples: samplesToEdit || [emptySample],
      },
    });
  const {
    fields: samples,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "samples",
  });

  useWatch({
    control,
    name: "samples",
    disabled: batchCreateAfterModalForm || savedValChanged,
  });

  const navigate = useNavigate();
  const backDestination = from || "/characterization";
  const cellIdIndices = samples.map((sample) =>
    sample.fields.findIndex(
      (sampleField) => sampleField.field_lookup_id === CELL_ID_LOOKUP_ID
    )
  );
  const sampleTypeIndices = samples.map((sample) =>
    sample.fields.findIndex(
      (sampleField) => sampleField.field_lookup_id === SAMPLE_TYPE_LOOKUP_ID
    )
  );
  const someSampleTypesEmpty = samples.some(
    (_, sampleIndex) =>
      !getValues(
        `samples.${sampleIndex}.fields.${sampleTypeIndices[sampleIndex]}.value`
      )
  );
  const originCellsCount = originCellIds ? originCellIds.length : 0;
  const numSamples = Math.max(
    originCellsCount,
    Number(numberSamplesToCreate),
    1
  );

  const maybeTriggerMaterialLookupForIndex = (sampleIndex: number) => {
    const materialFieldIndexes = samples[sampleIndex].fields
      .map(({ field_lookup_id }, _fieldIndex) => {
        if (
          [
            ICM_PCM_LOOKUP_ID,
            ...Object.values(ALTERNATE_MATERIAL_KEYS_FOR_SAMPLE_TYPE),
          ].includes(field_lookup_id)
        ) {
          return _fieldIndex;
        }
        return null;
      })
      .filter((indexVal) => !isNull(indexVal));
    const materialFields: FieldPath<CharacterizationSamples>[] = (
      materialFieldIndexes as number[]
    ).map(
      (fieldIndex) =>
        `samples.${sampleIndex}.fields.${fieldIndex}.value` as FieldPath<CharacterizationSamples>
    );
    const materialVals = getValues(materialFields);
    // if any icm/pcm fields have values, skip material lookup.
    if (materialVals.some((materialVal) => !isEmpty(materialVal))) return;

    const materialLookupDependencyFields: FieldPath<CharacterizationSamples>[] =
      [
        `samples.${sampleIndex}.fields.${cellIdIndices[sampleIndex]}.value`,
        `samples.${sampleIndex}.fields.${sampleTypeIndices[sampleIndex]}.value`,
      ];
    const dependencyVals: (string | string[])[] = getValues(
      materialLookupDependencyFields
    ) as (string | string[])[];
    // if any fields needed for material lookup are empty, skip lookup.
    if (
      dependencyVals.some((dependencyValue) =>
        Array.isArray(dependencyValue)
          ? isEmpty(dependencyValue)
          : !dependencyValue
      )
    )
      return;

    dispatch(
      getMaterials({
        sample: getValues(`samples.${sampleIndex}`),
        sampleIndex: sampleIndex,
      })
    );
  };

  useEffect(() => {
    if (!isEmpty(samplesToEdit)) {
      setIsSampleEdit(samplesToEdit!.every((sample_) => !!sample_.sample_id));
    }
  }, [samplesToEdit]);

  useEffect(() => {
    if (isSampleEdit && !savedValChanged && formState.isDirty) {
      setSavedValChanged(true);
    }
  }, [formState.isDirty, isSampleEdit, savedValChanged, setSavedValChanged]);

  useEffect(() => {
    if (!isEmpty(retrievedMaterialsForSampleIndex)) {
      const retrievedMaterials = retrievedMaterialsForSampleIndex!;
      const { material_id, description, sampleIndex, lookupId } =
        retrievedMaterials[0];
      const allSameMaterialIfPresent = retrievedMaterials.every(
        ({ material_id: _material_id }) =>
          !_material_id || _material_id === material_id
      );
      const materialFieldIndex = samples[sampleIndex].fields.findIndex(
        (_sampleField) => {
          return _sampleField.field_lookup_id === lookupId;
        }
      );

      if (materialFieldIndex !== -1 && allSameMaterialIfPresent) {
        const newVal = { material_id, description };
        setValue(
          `samples.${sampleIndex}.fields.${materialFieldIndex}.value`,
          newVal
        );
      }
    }
    // eslint-disable-next-line
  }, [retrievedMaterialsForSampleIndex, setValue]);

  useEffect(() => {
    if (originCellsCount === 1 && samples.length === 1) {
      const cellId = getValues(`samples.0.fields.${cellIdIndices[0]}.value`);
      if (!cellId) {
        setValue(
          `samples.0.fields.${cellIdIndices[0]}.value`,
          originCellIds![0]
        );
      }
    }
  }, [
    originCellIds,
    cellIdIndices,
    getValues,
    setValue,
    samples,
    originCellsCount,
  ]);

  useEffect(() => {
    if (modalForm && originCellsCount > 1) {
      setNumberSamplesToCreate("1");
    }
  }, [originCellIds, modalForm, originCellsCount]);

  return (
    <>
      {!modalForm && (
        <HeaderContainer variant="outlined">
          <Box px={18} py={3} display="flex" justifyContent="space-between">
            <Box flexShrink={0} display="flex">
              <IconButton
                style={{ marginRight: 12 }}
                onClick={() => navigate(backDestination)}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography style={{ marginTop: 4 }} variant="h2">
                {isSampleEdit
                  ? `Edit sample${samplesToEdit!.length > 1 ? "s" : ""}`
                  : "Add samples for characterization"}
              </Typography>
            </Box>
            <Box alignSelf="flex-end">
              <Button
                color="primary"
                size="small"
                type="submit"
                disabled={
                  (isSampleEdit && !savedValChanged) || someSampleTypesEmpty
                }
                onClick={handleSubmit((formData) =>
                  dispatch(saveSamples(formData))
                )}
              >
                {`${
                  isSampleEdit
                    ? `Update sample${samplesToEdit!.length > 1 ? "s" : ""}`
                    : `Create ${samples.length} sample${
                        samples.length > 1 ? "s" : ""
                      }`
                }`}
              </Button>
            </Box>
          </Box>
        </HeaderContainer>
      )}
      <Box style={{ marginBottom: modalForm ? 24 : 72 }}>
        <form>
          <Box
            display="flex"
            style={{
              background: colors.body.light,
              overflowX: "auto",
              paddingTop: modalForm ? 24 : 72,
              paddingLeft: 24,
            }}
          >
            {samples.map((sample, sampleIndex) => {
              const sampleType = getValues(
                `samples.${sampleIndex}.fields.${sampleTypeIndices[sampleIndex]}.value`
              );
              const cellId = getValues(
                `samples.${sampleIndex}.fields.${cellIdIndices[sampleIndex]}.value`
              );

              const columnHeader = isSampleEdit
                ? sampleIdToString(sample.sample_id!)
                : originCellIds
                ? cellIdToString(cellId as string)
                : null;

              return (
                <Table
                  size="small"
                  key={sample.id}
                  style={{ width: "auto", marginLeft: 12, height: "100%" }}
                >
                  {!modalForm && (
                    <TableHead>
                      <BaseTableRow>
                        <TableCell
                          style={{
                            display: "flex",
                            justifyContent: modalForm
                              ? "center"
                              : "space-between",
                            borderTop: 0,
                            borderBottom: 0,
                            backgroundColor: colors.striping,
                            height: sampleIndex === 0 ? 24 : undefined,
                            padding:
                              sampleIndex !== 0 && modalForm ? 0 : undefined,
                          }}
                        >
                          {sampleIndex !== 0 && (
                            <>
                              <>{columnHeader ? columnHeader : <Box />}</>
                              {isSampleEdit ? (
                                <Box />
                              ) : (
                                <Button
                                  color="tertiary"
                                  type="button"
                                  onClick={() => {
                                    dispatch(resetGetMaterials(sampleIndex));
                                    remove(sampleIndex);
                                  }}
                                  style={{ padding: 0, minWidth: 16 }}
                                >
                                  <Times />
                                </Button>
                              )}
                            </>
                          )}
                        </TableCell>
                        {sampleIndex === 0 && !!columnHeader && (
                          <TableCell
                            style={{ backgroundColor: colors.striping }}
                          >
                            {columnHeader}
                          </TableCell>
                        )}
                      </BaseTableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {modalForm && originCellsCount < 2 && (
                      <BaseTableRow>
                        <ThCell overridePaddingStyle={{ padding: "14px" }}>
                          # Samples to create
                        </ThCell>
                        <InputCell
                          inputProps={{ min: 1 }}
                          value={numberSamplesToCreate}
                          onChange={(event) => {
                            setNumberSamplesToCreate(event.target.value);
                          }}
                          type="number"
                          inputStyles={{ height: "100%" }}
                        />
                      </BaseTableRow>
                    )}
                    {sample.fields.map(
                      (
                        {
                          field_label,
                          field_lookup_id,
                          required,
                          conditional_for_sample_types,
                        },
                        sampleFormFieldIndex
                      ) => {
                        const notApplicableToSelectedSample =
                          !isEmpty(conditional_for_sample_types) &&
                          !conditional_for_sample_types?.includes(
                            sampleType as string
                          );
                        if (
                          notApplicableToSelectedSample ||
                          (!isEmpty(originCellIds) &&
                            FIELDS_TO_HIDE_IN_CELL_MODAL_FORM.includes(
                              field_lookup_id
                            )) ||
                          (!isEmpty(originCellIds) &&
                            field_lookup_id === EXP_ID_LOOKUP_ID)
                        ) {
                          return null;
                        }

                        let dependencyStateValMatchesCondition:
                          | boolean
                          | undefined;
                        const isConditionalField = Object.keys(
                          CONDITIONAL_FIELD_CONFIG
                        ).includes(field_lookup_id);
                        if (isConditionalField) {
                          const conditional_field_lookup_id =
                            field_lookup_id as
                              | "elyte_state_description"
                              | "elyte_storage"
                              | "hot_pocket_pcm"
                              | "seal_type";
                          const [lookupIdForDependencyField, valueToMatch] =
                            CONDITIONAL_FIELD_CONFIG[
                              conditional_field_lookup_id
                            ];
                          const dependencyStateFieldIndex =
                            sample.fields.findIndex(
                              ({ field_lookup_id }) =>
                                field_lookup_id === lookupIdForDependencyField
                            );
                          if (dependencyStateFieldIndex !== -1) {
                            dependencyStateValMatchesCondition = (
                              valueToMatch as (string | boolean)[]
                            ).includes(
                              getValues(
                                `samples.${sampleIndex}.fields.${dependencyStateFieldIndex}.value`
                              ) as string
                            );
                          }
                          if (!dependencyStateValMatchesCondition) {
                            return null;
                          }
                        }

                        let formRow;
                        switch (field_lookup_id) {
                          case SAMPLE_TYPE_LOOKUP_ID:
                            const sampleTypeVal = getValues(
                              `samples.${sampleIndex}.fields.${sampleFormFieldIndex}.value`
                            );
                            formRow = (
                              <Controller
                                name={`samples.${sampleIndex}.fields.${sampleFormFieldIndex}.value`}
                                control={control}
                                rules={{
                                  required: required || false,
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { invalid },
                                }) => (
                                  <TableCell padding="none" height={55}>
                                    <FormControl fullWidth>
                                      <Autocomplete
                                        disabled={
                                          isSampleEdit ||
                                          batchCreateAfterModalForm
                                        }
                                        options={CHARACTERIZATION_SAMPLE_TYPES.filter(
                                          (sampleType) => sampleType.enabled
                                        )}
                                        onChange={(_event, newVal) => {
                                          dispatch(
                                            resetGetMaterials(sampleIndex)
                                          );

                                          // nullify fields that are conditional on a sample type that is no longer selected.
                                          const newTypeVal =
                                            newVal?.id ||
                                            (newVal as string | null);
                                          sample.fields.forEach(
                                            (_displayedField, _fieldIndex) => {
                                              if (
                                                isEmpty(
                                                  _displayedField?.conditional_for_sample_types
                                                )
                                              )
                                                return;
                                              const conditionalForTypes =
                                                _displayedField.conditional_for_sample_types as string[];
                                              if (
                                                !newTypeVal ||
                                                !conditionalForTypes.includes(
                                                  newTypeVal
                                                )
                                              ) {
                                                setValue(
                                                  `samples.${sampleIndex}.fields.${_fieldIndex}.value`,
                                                  null
                                                );
                                              }
                                            }
                                          );

                                          onChange(newTypeVal);
                                          maybeTriggerMaterialLookupForIndex(
                                            sampleIndex
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            color="secondary"
                                            InputProps={{
                                              ...params.InputProps,
                                              error: invalid,
                                            }}
                                          />
                                        )}
                                        value={
                                          sampleTypeVal
                                            ? CHARACTERIZATION_SAMPLE_TYPES.find(
                                                (sampleType) =>
                                                  sampleType.id ===
                                                  sampleTypeVal
                                              )
                                            : null
                                        }
                                        renderOption={(props, { label }) => (
                                          <li {...props}>
                                            <Box px={4} py={2}>
                                              {label}
                                            </Box>
                                          </li>
                                        )}
                                      />
                                    </FormControl>
                                  </TableCell>
                                )}
                              />
                            );
                            break;
                          case EXP_ID_LOOKUP_ID:
                            formRow = (
                              <Controller
                                name={`samples.${sampleIndex}.fields.${sampleFormFieldIndex}.value`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                  fieldState: { invalid },
                                }) => (
                                  <MetaCell
                                    ref={ref}
                                    endpoint="meta/experiments/ids"
                                    prefix="EXP"
                                    error={invalid}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={
                                      value && expIdToString(value as string)
                                    }
                                  />
                                )}
                              />
                            );
                            break;
                          case CELL_ID_LOOKUP_ID:
                            formRow = (
                              <Controller
                                name={`samples.${sampleIndex}.fields.${sampleFormFieldIndex}.value`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                  fieldState: { invalid },
                                }) => (
                                  <MetaCell
                                    ref={ref}
                                    endpoint="meta/cells/ids"
                                    prefix="CEL"
                                    error={invalid}
                                    onBlur={onBlur}
                                    onChange={(newCellId) => {
                                      dispatch(resetGetMaterials(sampleIndex));
                                      onChange(newCellId);
                                      maybeTriggerMaterialLookupForIndex(
                                        sampleIndex
                                      );
                                    }}
                                    value={
                                      value && cellIdToString(value as string)
                                    }
                                  />
                                )}
                              />
                            );
                            break;
                          case "anode_pcm":
                          case "elyte_pcm":
                          case "hot_pocket_pcm":
                          case ICM_PCM_LOOKUP_ID:
                          case "arena_part_no":
                            formRow = (
                              <Controller
                                name={`samples.${sampleIndex}.fields.${sampleFormFieldIndex}.value`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                  fieldState: { invalid },
                                }) => (
                                  <MetaCell
                                    ref={ref}
                                    endpoint="meta/materials"
                                    /* characterization_form_serializer.py converts these lookup_id's values
                                       to a dictionary with the below key. They must also be packaged in a
                                       Material object in SampleSection.tsx.
                                     */
                                    valueKey="material_id"
                                    descriptionKey="description"
                                    error={invalid}
                                    onBlur={onBlur}
                                    showLoading={
                                      field_lookup_id !== "hot_pocket_pcm" &&
                                      getMaterialsStatus === "loading"
                                    }
                                    onChange={onChange}
                                    value={value}
                                  />
                                )}
                              />
                            );
                            break;
                          case "sample_owner_id":
                            formRow = (
                              <Controller
                                name={`samples.${sampleIndex}.sample_owners`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                  fieldState: { invalid },
                                }) => (
                                  <ConditionCell>
                                    <UserFilter
                                      removable={false}
                                      value={value || []}
                                      onChange={onChange}
                                      error={invalid}
                                      style={{ width: "100%" }}
                                    />
                                  </ConditionCell>
                                )}
                              />
                            );
                            break;
                          case "quantity":
                            const quantityUnitIndex = sample.fields.findIndex(
                              (field) =>
                                field.field_lookup_id ===
                                QUANTITY_UNIT_LOOKUP_ID
                            );
                            const loadedUnit =
                              (isSampleEdit || batchCreateAfterModalForm) &&
                              getValues(
                                `samples.${sampleIndex}.fields.${quantityUnitIndex}.value`
                              );

                            formRow = (
                              <ConditionCell
                                style={{
                                  display: "flex",
                                  height: "100%",
                                  border: 0,
                                }}
                              >
                                <Controller
                                  name={`samples.${sampleIndex}.fields.${sampleFormFieldIndex}.value`}
                                  control={control}
                                  rules={{
                                    required: !!getValues(
                                      `samples.${sampleIndex}.fields.${quantityUnitIndex}.value`
                                    ),
                                  }}
                                  render={({
                                    field: {
                                      onChange,
                                      onBlur,
                                      value,
                                      name,
                                      ref,
                                    },
                                    fieldState: { invalid },
                                  }) => (
                                    <InputCell
                                      noTable
                                      ref={ref}
                                      name={name}
                                      value={value || ""}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      error={invalid}
                                      style={{ flexShrink: 1 }}
                                      inputStyles={{ height: "100%" }}
                                      placeholder="amt."
                                    />
                                  )}
                                />
                                <Controller
                                  name={`samples.${sampleIndex}.fields.${quantityUnitIndex}.value`}
                                  control={control}
                                  rules={{
                                    required: !!getValues(
                                      `samples.${sampleIndex}.fields.${sampleFormFieldIndex}.value`
                                    ),
                                  }}
                                  render={({
                                    field: {
                                      onChange,
                                      onBlur,
                                      value,
                                      name,
                                      ref,
                                    },
                                    fieldState: { invalid },
                                  }) => (
                                    <Autocomplete
                                      fullWidth
                                      freeSolo
                                      options={QUANTITY_TYPE_OPTIONS}
                                      style={{ flexGrow: 2 }}
                                      onBlur={onBlur}
                                      onChange={(_event, newVal) => {
                                        let newValObj = newVal as {
                                          label: string;
                                          id: string;
                                        };
                                        onChange(newValObj?.id || newVal);
                                      }}
                                      value={
                                        loadedUnit
                                          ? QUANTITY_TYPE_OPTIONS.find(
                                              (quanitityType) =>
                                                quanitityType.id === loadedUnit
                                            )
                                          : null
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          color="secondary"
                                          InputProps={{
                                            ...params.InputProps,
                                            error: invalid,
                                          }}
                                          placeholder="unit"
                                        />
                                      )}
                                      renderOption={(
                                        props: any,
                                        { label }: { label: string }
                                      ) => (
                                        <li {...props}>
                                          <Box px={4} py={2}>
                                            {label}
                                          </Box>
                                        </li>
                                      )}
                                    />
                                  )}
                                />
                              </ConditionCell>
                            );
                            break;
                          case "form_factor":
                          case "type_of_material_anode":
                          case "type_of_material_gde":
                          case "type_of_material_oee":
                          case ELYTE_STATE_LOOKUP_ID:
                          case LOCATION_LOOKUP_ID:
                          case "oee_mesh_type":
                          case "elyte_storage":
                          case "sample_prep_sludge":
                          case "sandblasted":
                          case ANALYSIS_TYPE_LOOKUP_ID:
                          case ASANA_TASK_TEMPLATE_ID_LOOKUP_ID:
                          case LAB_LOCATION_LOOKUP_ID:
                            const lookupId = field_lookup_id as
                              | "elyte_state"
                              | "elyte_storage"
                              | "form_factor"
                              | "location"
                              | "oee_mesh_type"
                              | "sample_prep_sludge"
                              | "sandblasted"
                              | "type_of_material_anode"
                              | "type_of_material_oee"
                              | "type_of_material_gde"
                              | "lab_location"
                              | "analysis_type"
                              | "asana_task_template_id";

                            const optionsByField = {
                              elyte_state: ELYTE_STATE_OPTIONS,
                              elyte_storage: ELYTE_STORAGE_OPTIONS,
                              form_factor:
                                sampleType === "anode"
                                  ? ANODE_FORM_FACTOR_OPTIONS
                                  : ALL_FORM_FACTOR_OPTIONS,
                              location:
                                sampleType === ELYTE_LOOKUP_ID
                                  ? ELYTE_SAMPLE_LOCATION_OPTIONS
                                  : SAMPLE_LOCATION_OPTIONS,
                              sample_prep_sludge: SAMPLE_TYPE_SLUDGE_OPTIONS,
                              sandblasted: SANDBLASTED_OPTIONS,
                              oee_mesh_type: OEE_MESH_TYPE_OPTIONS,
                              type_of_material_anode: ANODE_MATERIAL_OPTIONS,
                              type_of_material_gde: GDE_MATERIAL_OPTIONS,
                              type_of_material_oee: OEE_MATERIAL_OPTIONS,
                              lab_location:
                                ASANA_CHARACTERIZATION_SAMPLE_LOCATION_OPTIONS,
                              analysis_type:
                                ASANA_CHARACTERIZATION_ANALYSIS_TYPE_OPTIONS,
                              asana_task_template_id:
                                ASANA_CHARACTERIZATION_TASK_TEMPLATE_ID_OPTIONS,
                            };
                            formRow = (
                              <Controller
                                name={`samples.${sampleIndex}.fields.${sampleFormFieldIndex}.value`}
                                control={control}
                                rules={{
                                  required: isConditionalField
                                    ? !!required &&
                                      dependencyStateValMatchesCondition
                                    : required || false,
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { invalid },
                                }) => (
                                  <ConditionCell>
                                    <FormControl fullWidth>
                                      <Autocomplete
                                        options={optionsByField[lookupId]}
                                        onBlur={onBlur}
                                        onChange={(_event, newVal) => {
                                          onChange(newVal?.id || newVal);
                                        }}
                                        style={{
                                          borderBottom: 0,
                                          borderTop: 0,
                                        }}
                                        value={optionsByField[lookupId].find(
                                          ({ id }) => id === value
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            color="secondary"
                                            InputProps={{
                                              ...params.InputProps,
                                              error: invalid,
                                            }}
                                          />
                                        )}
                                        renderOption={(props, { label }) => (
                                          <li {...props}>
                                            <Box px={4} py={2}>
                                              {label}
                                            </Box>
                                          </li>
                                        )}
                                      />
                                    </FormControl>
                                  </ConditionCell>
                                )}
                              />
                            );
                            break;
                          case "air_sensitive":
                          case HOT_POCKET_LOOKUP_ID:
                          case "precipitates":
                          case CREATE_ASANA_TASK_LOOKUP_ID:
                            formRow = (
                              <Controller
                                control={control}
                                name={`samples.${sampleIndex}.fields.${sampleFormFieldIndex}.value`}
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                  fieldState: { invalid },
                                }) => (
                                  <CheckboxCell
                                    color="secondary"
                                    checked={!!value}
                                    onChange={onChange}
                                  />
                                )}
                              />
                            );
                            break;
                          case "description":
                          case "elyte_state_description":
                            formRow = (
                              <Controller
                                name={`samples.${sampleIndex}.fields.${sampleFormFieldIndex}.value`}
                                control={control}
                                rules={{
                                  required: isConditionalField
                                    ? !!required &&
                                      dependencyStateValMatchesCondition
                                    : required || false,
                                }}
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                  fieldState: { invalid },
                                }) => (
                                  <TextareaCell
                                    ref={ref}
                                    name={name}
                                    value={value || ""}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    error={invalid}
                                  />
                                )}
                              />
                            );
                            break;
                          case DATE_SAMPLE_HARVESTED_LOOKUP_ID:
                            /* The harvested date is required for Asana task creation
                             * in the backend, so require it to be filled in if the
                             * Create Asana Task box is checked.
                             *
                             * The required status is only applied after clicking "Fill in data"
                             * from the modal, when clicking "Create N samples" on the page with
                             * the /characterization/new-samples URL.
                             */
                            const asanaTaskIndex = sample.fields.findIndex(
                              (field) =>
                                field.field_lookup_id ===
                                CREATE_ASANA_TASK_LOOKUP_ID
                            );
                            formRow = (
                              <Controller
                                name={`samples.${sampleIndex}.fields.${sampleFormFieldIndex}.value`}
                                control={control}
                                rules={{
                                  required: !!getValues(
                                    `samples.${sampleIndex}.fields.${asanaTaskIndex}.value`
                                  ),
                                }}
                                render={({
                                  field: { onChange, value, name, ref },
                                  fieldState: { invalid },
                                }) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterLuxon}
                                  >
                                    <MuiDateTimePicker
                                      renderInput={(props) => (
                                        <ConditionCell>
                                          <TextField
                                            {...props}
                                            onKeyDown={(e) =>
                                              e.preventDefault()
                                            }
                                            error={invalid}
                                          />
                                        </ConditionCell>
                                      )}
                                      value={
                                        value ? value.toLocaleString() : ""
                                      }
                                      onChange={(dateVal: Date | null) => {
                                        dateVal
                                          ? onChange(
                                              new Date(dateVal).toISOString()
                                            )
                                          : onChange(null);
                                      }}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                            );
                            break;
                          default:
                            formRow = (
                              <Controller
                                name={`samples.${sampleIndex}.fields.${sampleFormFieldIndex}.value`}
                                control={control}
                                rules={{
                                  required: required || false,
                                }}
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                  fieldState: { invalid },
                                }) => (
                                  <InputCell
                                    ref={ref}
                                    name={name}
                                    value={
                                      getValues(
                                        `samples.${sampleIndex}.fields.${sampleFormFieldIndex}.value`
                                      ) || ""
                                    }
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    error={invalid}
                                  />
                                )}
                              />
                            );
                        }
                        return SHARED_ROW_FIELDS.includes(
                          field_lookup_id
                        ) ? null : (
                          <BaseTableRow
                            key={`${field_lookup_id}-${sampleFormFieldIndex}`}
                            disabled={
                              modalForm &&
                              !sampleType &&
                              sampleFormFieldIndex !==
                                sampleTypeIndices[sampleIndex]
                            }
                          >
                            {((!isSampleEdit && !batchCreateAfterModalForm) ||
                              sampleIndex === 0) && (
                              <ThCell
                                required={required || false}
                                overridePaddingStyle={{ padding: "14px" }}
                              >
                                {field_label}
                              </ThCell>
                            )}
                            {formRow}
                          </BaseTableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              );
            })}
            {!isSampleEdit && !modalForm && (
              <div
                style={{
                  backgroundColor: "rgba(231, 231, 231, .2)",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 24px",
                  marginBottom: 12,
                  marginLeft: 16,
                }}
              >
                <Button
                  color="tertiary"
                  type="button"
                  style={{ display: "block" }}
                  onClick={() => {
                    const lastSampleIndex = getValues("samples").length - 1;
                    const sampleToClone = getValues(
                      `samples.${lastSampleIndex}`
                    );
                    append(sampleToClone);
                  }}
                >
                  <div>
                    <b>Add another sample</b>
                  </div>
                  <div>
                    <PlusCircle />
                  </div>
                </Button>
              </div>
            )}
          </Box>
        </form>
      </Box>
      {modalForm && onCompleteModalForm && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="primary"
            type="button"
            disabled={
              copyingFromModalForm || someSampleTypesEmpty || numSamples === 0
            }
            onClick={() => {
              setCopyingFromModalForm(true);
              const sampleToClone = getValues("samples.0");
              if (originCellsCount > 1) {
                const cellIdFieldIndex = sampleToClone.fields.findIndex(
                  (sampleField) =>
                    sampleField.field_lookup_id === CELL_ID_LOOKUP_ID
                );
                originCellIds!.forEach((cellId: string, index: number) => {
                  if (index !== 0) {
                    append(sampleToClone);
                  }
                  setValue(
                    `samples.${index}.fields.${cellIdFieldIndex}.value`,
                    cellId
                  );
                });
              } else {
                const times = parseInt(numberSamplesToCreate) || 1;
                for (var i = 0; i < times; i++) {
                  if (i !== 0) {
                    append(sampleToClone);
                  }
                }
              }

              onCompleteModalForm(getValues("samples"));
            }}
          >
            {`Fill in data for ${numSamples} sample${
              numSamples === 1 ? "" : "s"
            }`}
            {copyingFromModalForm && (
              <CircularProgress
                color="inherit"
                size={20}
                style={{ marginLeft: 8 }}
              />
            )}
          </Button>
        </div>
      )}
      {createSampleError && (
        <Toast
          open
          severity="error"
          onClose={() => dispatch(resetSaveSamples())}
        >
          {createSampleError}
        </Toast>
      )}
      <Modal
        open={createdSampleIds.length > 0}
        onClose={() => {
          dispatch(resetSaveSamples());
          navigate(
            `/characterization/edit-samples?sample_id=${createdSampleIds[0]}`
          );
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Box my={4}>
            <Typography variant="h2">
              {`Sample${createdSampleIds.length > 1 ? "s" : ""} ${
                isSampleEdit ? "updated" : "created"
              }.`}
            </Typography>
          </Box>
          <Box pt={2} px={6} pb={4}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Sample ID</TableCell>
                    <TableCell>Cell ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {createdSampleIds.map((sampleId_: string, i) => {
                    const cellId_ =
                      createdSampleIdsToCellIds[parseInt(sampleId_)];
                    return (
                      <TableRow key={i}>
                        <TableCell>{sampleIdToString(sampleId_)}</TableCell>
                        <TableCell>
                          {cellId_ ? cellIdToString(cellId_) : "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box my={4}>
            <Button
              color="secondary"
              onClick={() => {
                if (from) {
                  navigate(from);
                } else {
                  navigate(
                    `/characterization/samples?sample_id=${createdSampleIds.join(
                      ","
                    )}`,
                    { state: { from: { pathname: from } } }
                  );
                }
                dispatch(resetSaveSamples());
              }}
              style={{
                marginRight: 12,
              }}
            >
              {from
                ? "Back"
                : `Full details for ${
                    isSampleEdit ? "updated" : "created"
                  } sample${createdSampleIds.length > 1 ? "s" : ""}`}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                navigate("/characterization");
                dispatch(resetSaveSamples());
              }}
              style={{ marginRight: 12 }}
            >
              Samples list
            </Button>
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default SamplesForm;
