import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import type { FieldArrayWithId } from "react-hook-form";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import BaseTableRow from "../../../components/forms/BaseTableRow";
import UserCell from "../../../components/forms/UserCell";
import ConditionCell from "../../../components/forms/ConditionCell";
import type { RootState } from "../../../store";
import type { AuthState } from "../../../store/auth/slice";
import CellHeader from "./CellHeader";
import TestCell from "./TestCell";

type Props = {
  formFields: FieldArrayWithId<MetadataRHFormFormat, "metadata">[];
  step: number;
  executor?: User | null;
  onSave: (callback?: () => void) => void;
};

const GenerateTestSubSection = ({
  formFields,
  step,
  executor: preferredExecutor,
  onSave,
}: Props) => {
  // Auth state in redux
  const { user } = useSelector<RootState, AuthState>(({ auth }) => auth);

  // Fields from the component data
  const [executor, setExecutor] = useState<User | null>(
    preferredExecutor || user
  );
  useEffect(() => {
    setExecutor(preferredExecutor || user);
  }, [user, preferredExecutor]);

  return (
    <>
      <TableHead>
        <BaseTableRow>
          <TableCell>EXECUTOR</TableCell>
          <TableCell>ACTIONS</TableCell>
          {formFields.map((field, fieldIndex) => (
            <CellHeader key={fieldIndex} cell_id={field.cell_id} />
          ))}
        </BaseTableRow>
      </TableHead>

      <TableBody>
        <BaseTableRow>
          <UserCell
            clearable={false}
            style={{ width: 254, minWidth: 254 }}
            error={!executor}
            value={executor}
            onChange={setExecutor}
          />
          <ConditionCell smallWidth></ConditionCell>
          {formFields.map((field, fieldIndex) => (
            <ConditionCell
              key={fieldIndex}
              className={
                field.items[step].completed?.completed_at
                  ? "componentCompleted"
                  : ""
              }
            >
              <TestCell
                executor={executor}
                cell_id={field.cell_id}
                cell_condition_id={field.cell_condition_id!}
                cell_assembly={field.cell_assembly}
                reserved_channel={field.reserved_channel}
                reserved_test_stand={field.reserved_test_stand}
                enable_standard_tests={
                  field.status === "P"
                    ? ["L", "N", "F"].includes(field.status_no_preflight)
                    : ["L", "O", "N", "F"].includes(field.status)
                }
                // Only need to disable previous steps for subscale cells
                disabled_prev_steps={
                  !field.cell_assembly.includes("FSC") &&
                  !["L", "P", "N", "F"].includes(field.status)
                }
                disabled_pre_committed={["I", "S", "T"].includes(field.status)}
                disabled_module={!isEmpty(field.module)}
                module={field.module}
                completed_at={field.items[step].completed?.completed_at}
                completed_by={field.items[step].completed?.completed_by}
                onSave={onSave}
                warnNoQCCheck={
                  field.quality_check &&
                  !field.quality_check.quality_check_completed_at
                }
                isFullScale={field.cell_assembly.includes("FSC")}
              />
            </ConditionCell>
          ))}
        </BaseTableRow>
      </TableBody>
    </>
  );
};

export default GenerateTestSubSection;
