export const hasPassedStatus = (
  status: CellStatus,
  currentStatus: CellStatus,
  inclusive: boolean = true
) => {
  const statusOrder: CellStatus[] = [
    "I",
    "S",
    "T",
    "C",
    "P",
    "A",
    "M",
    "D",
    "E",
    "L",
    "N",
    "F",
    "O",
    "U",
  ];

  return inclusive
    ? statusOrder.indexOf(status) <= statusOrder.indexOf(currentStatus)
    : statusOrder.indexOf(status) < statusOrder.indexOf(currentStatus);
};
